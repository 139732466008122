<template>
    <h1 class="page-header">
        {{ $t( "pages.aboutUs.pageHeadline" ) }}
    </h1>

    <p class="body-text">
        {{ $t( "pages.aboutUs.paragraph1" ) }}
    </p>

    <p class="body-text">
        {{ $t( "pages.aboutUs.paragraph2" ) }}
    </p>

    <p class="body-text">
        {{ $t( "pages.aboutUs.paragraph3" ) }}
    </p>

    <p class="body-text">
        {{ $t( "pages.aboutUs.paragraph4" ) }}
    </p>

    <p class="body-text">
        {{ $t( "pages.aboutUs.paragraph5" ) }}
    </p>

    <div class="three-pic-card-row d-md-flex justify-content-md-between align-content-md-between">

        <!------------- BEGIN: Teaser one ------------->

        <n-card class="card pic-card mb-2" :title="$t( 'pages.aboutUs.cardOne.headline' )">
            <template #cover>
                <img
                    src="../assets/images/pages/Schnuppertauchen_c.jpg"
                    class="card-img-top img-fluid"
                    alt="W-Diving - Diving-Center - Greece - Kalafati-Beach"
                />
            </template>
            <p class="card-text">{{ $t( "pages.aboutUs.cardOne.teaser" ) }}</p>
            <router-link :to="linkTo('discover')" v-slot="{ navigate }">
                <n-button @click="navigate" type="primary" ghost>{{ $t( "buttons.learnMore" ) }}</n-button>
            </router-link>
        </n-card>

        <!------------- END: Teaser one ------------->
        <!------------- BEGIN: Teaser two ------------->

        <n-card class="card pic-card mb-2" :title="$t( 'pages.aboutUs.cardTwo.headline' )">
            <template #cover>
                <img
                    src="../assets/images/pages/Boots_TG_c.jpg"
                    class="card-img-top img-fluid"
                    alt="W-Diving - Diving-Center - Greece - Kalafati-Beach"
                />
            </template>
            <p class="card-text">{{ $t( "pages.aboutUs.cardTwo.teaser" ) }}</p>
            <router-link :to="linkTo('boat')" v-slot="{ navigate }">
                <n-button @click="navigate" type="primary" ghost>{{ $t( "buttons.learnMore" ) }}</n-button>
            </router-link>
        </n-card>

        <!------------- END: Teaser two ------------->
        <!------------- BEGIN: Teaser three ------------->

        <n-card class="card pic-card mb-2" :title="$t( 'pages.aboutUs.cardThree.headline' )">
            <template #cover>
                <img
                    src="../assets/images/pages/Padi_Tauchkurse_c.jpg"
                    class="card-img-top img-fluid"
                    alt="W-Diving - Diving-Center - Greece - Kalafati-Beach"
                />
            </template>
            <p class="card-text">{{ $t( "pages.aboutUs.cardThree.teaser" ) }}</p>
            <router-link :to="linkTo('padi')" v-slot="{ navigate }">
                <n-button @click="navigate" type="primary" ghost>{{ $t( "buttons.learnMore" ) }}</n-button>
            </router-link>
        </n-card>

        <!------------- END: Teaser three ------------->
    </div>

</template>

<script>
export default {
    methods: {
        linkTo( name ) {
            return this.$helper.routerLinkGet( this, name )
        }
    }
}
</script>

<style scoped>
.three-pic-card-row {
    margin: 1.5rem 0;
}
</style>